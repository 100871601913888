import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {SoldAppsTable} from '../../modules/dashboard/tables/SoldAppsTable'

const DashboardPage = () => {
  return <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      <SoldAppsTable className="mb-5 mb-xl-8"/>
    </div>
    {/* end::Row */}
  </>;
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
