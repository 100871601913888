import axios from 'axios'
import {SoldAppModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const SOLD_APPS_URL = `${API_URL}/api/sold_app`


export function get_sold_apps() {
  return axios.get<SoldAppModel[]>(`${SOLD_APPS_URL}/`)
}

export function get_app_versions(uuid: string) {
  return axios.get<string[]>(`${SOLD_APPS_URL}/${uuid}/`)
}

export function stop_app(uuid: string) {
  return axios.delete<SoldAppModel>(`${SOLD_APPS_URL}/${uuid}/`)
}

export function set_app_version(version: string, uuid: string) {
  return axios.post<SoldAppModel>(`${SOLD_APPS_URL}/`, {
    uuid: uuid,
    version: version,
  })
}
