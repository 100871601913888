/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {SoldAppModel} from "../_models";
import {get_app_versions, set_app_version, stop_app} from "../_requests";

type Props = {
  sold_app_arg: SoldAppModel,
}

const SoldAppRow: React.FC<Props> = ({sold_app_arg}) => {
  let [sold_app, setSoldApp] = useState(sold_app_arg);
  let [versions, setVersions] = useState([] as string[]);
  let [selectedVersion, setSelectedVersion] = useState("");

  async function get_versions_handler(_: any) {
    let {data} = await get_app_versions(sold_app.uuid);
    if (data.length > 0) {
      setSelectedVersion(data[0]);
    }
    setVersions(data);
  }

  async function update_handler(_: any) {
    let {data} = await set_app_version(selectedVersion, sold_app.uuid);
    setSoldApp(data);
  }

  async function stop_handler(_: any) {
    let {data} = await stop_app(sold_app.uuid);
    setSoldApp(data);
  }

  const handleChangeString = (e: { target: { value: any; }; }, setFn: (arg0: any) => void) => {
    setFn(e.target.value);
  };

  return (
    <tr>
      <td>
        <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
          <div className='fw-bold text-hover-primary mb-1 fs-6'>
            {sold_app.title ? sold_app.title : "No title"}
          </div>
          <span className='text-muted fw-semibold text-muted d-block fs-7'>
            {sold_app.uuid}
          </span>
        </div>
      </td>
      <td>
        <div className='text-hover-primary d-block mb-1 fs-6'>
          {sold_app.user_id}
        </div>
      </td>
      <td>
        <div className='text-hover-primary d-block mb-1 fs-6'>
          {sold_app.app_id}
        </div>
      </td>
      <td>
        <div className='text-hover-primary d-block mb-1 fs-6'>
          {sold_app.valid_until.toString()}
        </div>
      </td>
      <td>
        <div className='text-hover-primary d-block mb-1 fs-6'>
          {sold_app.app_image}
        </div>
      </td>
      <td>
        <div className='text-hover-primary d-block mb-1 fs-6'>
          {sold_app.container_name}
        </div>
      </td>
      <td>
        <div className='text-hover-primary d-block mb-1 fs-6'>
          {sold_app.deployment_status}
        </div>
      </td>
      <td className='text-end'>
        <div className='d-flex justify-content-end flex-shrink-0'>
          <button
            className="btn btn-light btn-sm"
            onClick={get_versions_handler}
          >
            <i className="bi bi-arrow-repeat text-primary"/>
          </button>
          <select
            className={`form-select form-select-solid form-select-sm ms-1 ${versions.length === 0 ? "" : "text-primary"}`}
            data-kt-select2='true'
            data-placeholder='Select option'
            data-allow-clear='true'
            // defaultValue={'1'}
            onChange={(e) => handleChangeString(e, setSelectedVersion)}
            disabled={versions.length === 0}
          >
            {versions.map((version, index) => {
              return <option value={version}>{version}</option>
            })}
          </select>
          <button
            className={
              `btn btn-bg-light btn-color-muted btn-sm px-4 ms-1`
            }
            onClick={update_handler}
            disabled={versions.length === 0}
          >
            <i className={`bi bi-arrow-up-circle-fill ${versions.length === 0 ? "" : "text-warning"}`}/>
          </button>
          <button
            className='btn btn-light btn-sm px-4 ms-1'
            onClick={stop_handler}
          >
            <i className="bi bi-dash-circle-fill text-danger"/>
          </button>
        </div>
      </td>
    </tr>
  )
}

export {SoldAppRow}
