/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen025.svg'
        // fontIcon='bi-cpu-fill mx-2'
        title={intl.formatMessage({id: 'MENU.SOLD_APPS'})}
      />
      <AsideMenuItem
        to='/settings'
        // icon='/media/icons/duotune/coding/cod001.svg'
          fontIcon='bi-sliders mx-2'
        title={intl.formatMessage({id: 'MENU.SETTINGS'})}
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  )
}
