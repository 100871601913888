/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {SoldAppModel} from "../_models";
import {get_sold_apps} from "../_requests";
import {SoldAppRow} from "../components/SoldAppRow";

type Props = {
  className: string
}

const SoldAppsTable: React.FC<Props> = ({className}) => {
  let [soldApps, setSoldApps] = useState([] as SoldAppModel[])
  const refreshTable = async () => {
    const requestApps = async () => {
      try {
        const {data} = await get_sold_apps();
        setSoldApps(data);
      } catch (error) {
        console.log(error);
      }
    }
    requestApps().then();
  };

  useEffect(() => {
    refreshTable().then();
  }, []);

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Sold Applications</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>The list of applications sold to clients</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-50px rounded-start'>Title</th>
                <th className='min-w-50px'>User ID</th>
                <th className='min-w-50px'>App ID</th>
                <th className='min-w-50px'>Valid until</th>
                <th className='min-w-50px'>App image</th>
                <th className='min-w-50px'>Container name</th>
                <th className='min-w-50px'>Deployment status</th>
                <th className='min-w-50px  text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {soldApps.map(sold_app => {
              return <SoldAppRow  sold_app_arg={sold_app}/>
            })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {SoldAppsTable}
